<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      채점 결과
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <div class="box-ct">
          <div class="text-right">
              <span class="testform-tag">
                  <span class="testform-tit">문항수</span>
                  <span class="testform-txt">{{grid01Selected.LM_EVA_RST_COUNT}}</span>
              </span>
              <span class="testform-tag">
                  <span class="testform-tit">점수/최종 점수</span>
                  <span class="testform-txt">{{getTotalSc}}/{{grid01Selected.LM_EVA_SUM}}</span>
              </span>
              <span class="testform-tag">
                  <span class="testform-tit">시작시간</span>
                  <span class="testform-txt">{{USER_ANS[0].ST_TIME}}</span>
              </span>
              <span class="testform-tag">
                  <span class="testform-tit">진행시간</span>
                  <span class="testform-txt">{{USER_ANS[0].USE_TIME > 60 ? (USER_ANS[0].USE_TIME / 60).toFixed(0) + '분' : USER_ANS[0].USE_TIME +'초'}}</span>
              </span>
              <template v-if="!isView">
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnStartChoice')" outlined class="btn-point ml-2" :disabled="USER_ANS[0].LM_GRADING_YN == 'Y'"
                  @click="updateRtnLmGrading()">객관식 채점</v-btn>
                <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnStart')" outlined class="btn-etc ml-2" :disabled="USER_ANS[0].LM_GRADING_YN == 'Y'"
                  @click="processRtnLmGrading()">채점 완료</v-btn>
              </template>
          </div>
        </div>
        <div class="box-ct mt-3">
          <div class="table-form">
            <table>
              <caption class="hide">
                항목
              </caption>
              <colgroup>
                <col width="" />
                <col width="136px" />
                <col width="136px" />
                <col width="136px" />
              </colgroup>
              <thead>
                <th scope="col" class="py-2"></th>
                <th scope="col">대상자 선택</th>
                <th scope="col">정답</th>
                <th scope="col" style="background: #868894; color: #FFF">점수</th>
              </thead>
              <tbody>
                <template v-for="(lmQs, index) in USER_ANS">
                  <tr :key="index">
                    <td style="background: #EFEFEF" colspan="3">[문제 {{index+1}}] {{lmQs.LM_QS_NM}} [{{lmQs.LM_QS_TY_SC}}점]</td>
                    <template v-if="lmQs.LM_QS_TY == '20'">
                      <td class="text-center" style="background: #EFEFEF">
                        <template v-if="lmQs.LM_GRADING_YN == 'Y'">
                          {{lmQs.LM_DATA_RST_SUM}}
                        </template>
                        <template v-else>
                          <v-text-field class="form-inp sm ml-2" name="LO_SC" id="id" :LM_QS_ID="lmQs.LM_QS_ID"
                            outlined hide-details label="점수" type="number" min="0" :max="lmQs.LM_QS_TY_SC"
                            @change="getLoSc"></v-text-field>
                        </template>
                      </td>
                    </template>
                    <template v-else>
                      <td class="text-center" style="background: #EFEFEF">{{lmQs.LM_DATA_RST_SUM}}</td>
                    </template>
                  </tr>
                  <template v-for="(lmVe, index2) in USER_ANS_DTL">
                    <template v-if="lmVe.LM_QS_ID == lmQs.LM_QS_ID">
                      <tr :key="index + '_' + index2">
                        <template v-if="lmQs.LM_QS_TY == '20'">
                          <td>정답풀이 : {{lmQs.LM_QS_ANS_COMM}}</td>
                          <td>{{lmVe.LM_QS_VE_LO_ANS}}</td>
                          <td></td>
                          <td></td>
                        </template>
                        <template v-else>
                          <td>{{lmVe.VE_NUMBER}}. {{lmVe.LM_QS_VE_RT}}</td>
                          <td class="text-center">
                            <v-icon small class="svg-test-check" v-if="lmVe.USER_ANS_YN == 'Y'"></v-icon>
                          </td>
                          <td class="text-center">
                            <v-icon small class="svg-test-check" v-if="lmVe.ANS_YN == 'Y'"></v-icon>
                          </td>
                          <td></td>
                        </template>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import api from "@/store/apiUtil.js"
  import {
    mixin
  } from "@/mixin/mixin.js"

  export default {
    name: "MENU_M410401P01",
    mixins: [mixin],
    props: {
      LM_DATA_ID: {},
      grid01Selected: {},
      aspCustKey: "",
      isView: {
        type: Boolean,
        default: true,
      }
    },
    data: () => ({
      USER_ANS: [{
        ST_TIME: ""
      }],
      USER_ANS_DTL: [],
      loSc: 0,
      totalSc: 0,
    }),
    created() {
      this.selectRtnLmData();
    },
    watch: {

    },
    methods: {
      async selectRtnLmData() {
        let requestData = {
          headers: {},
          sendData: {}
        };
        // header 세팅
        requestData.headers["URL"] = "api/phone/lm/grading/user-ans/inqire";
        requestData.headers["SERVICE"] = "phone.lm.grading.user-ans";
        requestData.headers["METHOD"] = "inqire";
        requestData.headers["TYPE"] = "BIZ_SERVICE";
        requestData.headers["ASYNC"] = false;

        // sendData 세팅
        requestData.sendData['ASP_NEWCUST_KEY'] = this.aspCustKey;
        requestData.sendData['LM_DATA_ID'] = this.LM_DATA_ID;

        // 결과 리턴
        let response = await this.common_postCall(requestData);

        if (!response.HEADER.ERROR_FLAG) {
          this.USER_ANS = response.USER_ANS;
          this.USER_ANS_DTL = response.USER_ANS_DTL;
          this.getScSum();
        } else {
          this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.", "error");
        }
      },
      //객관식 채점
      async updateRtnLmGrading() {
        if (this.USER_ANS.length > 0) {
          let requestData = {
            headers: {},
            sendData: {}
          };
          // header 세팅
          requestData.headers["URL"] = "api/phone/lm/grading/multi-choice/regist";
          requestData.headers["SERVICE"] = "phone.lm.grading.multi-choice";
          requestData.headers["METHOD"] = "regist";
          requestData.headers["TYPE"] = "BIZ_SERVICE";
          requestData.headers["ASYNC"] = false;

          // sendData 세팅
          requestData.sendData['LM_ID'] = this.grid01Selected.LM_ID;
          requestData.sendData['LM_DATA_ID'] = this.LM_DATA_ID;
          requestData.sendData['USER_ID'] = this.$store.getters['userStore/GE_USER_ID'];
          requestData.sendData['USER_NM'] = this.$store.getters['userStore/GE_USER_NAME'];
          requestData.sendData['ASP_NEWCUST_KEY'] = this.aspCustKey;

          // 결과 리턴
          let response = await this.common_postCall(requestData);

          if (!response.HEADER.ERROR_FLAG) {
            this.common_alert("정상적으로 처리되었습니다.", "done");
            this.selectRtnLmData();
          } else {
            this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.", "error");
          }
        } else {
          this.common_alert("채점할 데이터가 존재하지 않습니다.", "error");
        }
      },
      async processRtnLmGrading() {
        let loScList = document.getElementsByName("LO_SC");
        let loScData = [];
        if (loScList != undefined && loScList.length > 0) {
          for (let i = 0; i < loScList.length; i++) {
            if (loScList[i].value == '') {
              this.common_alert("서술형 점수를 입력해주세요.", "error");
              return;
            } else if (parseInt(loScList[i].value) < 0) {
              this.common_alert("서술형 점수를 확인해주세요.", "error");
              return;
            } else if (parseInt(loScList[i].value) > parseInt(loScList[i].getAttribute("MAX"))) {
              this.common_alert("서술형 점수를 확인해주세요.", "error");
              return;
            } else {
              loScData.push({
                LM_QS_ID: loScList[i].getAttribute("LM_QS_ID"),
                LM_DATA_RST_SUM: loScList[i].value,
              });
            }
          }

          let requestData = {
            headers: {},
            sendData: {}
          };
          // header 세팅
          requestData.headers["URL"] = "api/phone/lm/grading/regist";
          requestData.headers["SERVICE"] = "phone.lm.grading";
          requestData.headers["METHOD"] = "regist";
          requestData.headers["TYPE"] = "BIZ_SERVICE";
          requestData.headers["ASYNC"] = false;
          requestData.headers["DATA_OBJECT"] = "LO_SC_DATA";

          // sendData 세팅
          requestData.sendData['LM_ID'] = this.grid01Selected.LM_ID;
          requestData.sendData['LM_DATA_ID'] = this.LM_DATA_ID;
          requestData.sendData['USER_ID'] = this.$store.getters['userStore/GE_USER_ID'];
          requestData.sendData['USER_NM'] = this.$store.getters['userStore/GE_USER_NAME'];
          requestData.sendData['LO_SC_DATA'] = loScData;
          requestData.sendData['ASP_NEWCUST_KEY'] = this.aspCustKey;

          // 결과 리턴
          let response = await this.common_postCall(requestData);

          if (!response.HEADER.ERROR_FLAG) {
            this.common_alert("정상적으로 처리되었습니다.", "done");
            this.selectRtnLmData();
            this.$emit('callback');
          } else {
            this.common_alert("시스템 장애가 일어났습니다.<br>잠시후에 다시 시도해주세요.", "error");
          }

          console.log(loScData);
        }


      },
      getLoSc() {
        this.loSc = 0;
        let loScList = document.getElementsByName("LO_SC");
        if (loScList != undefined && loScList.length > 0) {
          for (let i = 0; i < loScList.length; i++) {
            if (loScList[i].value != '' && parseInt(loScList[i].value) > 0) {
              this.loSc += parseInt(loScList[i].value);
            }
          }
        }
      },
      getScSum() {
        let sc = 0;
        this.USER_ANS.map(e => {
          if (parseInt(e.LM_DATA_RST_SUM) > 0) {
            sc += parseInt(e.LM_DATA_RST_SUM);
          }
        })

        this.totalSc = sc;

      },
    },
    computed: {
      getTotalSc() {
        return this.totalSc + this.loSc;
      }
    },

  }
</script>